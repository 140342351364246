<template>
    <div>
        <p>Verificando suas credenciais...</p>
    </div>
</template>

<script>

import store from "@/store";
import env from "@/env";

export default {

    methods: {
        async verifyCredentials() {
            const token = this.$route.params.token;

            // Verifica se o token existe
            if (token) {
                try {
                    localStorage.setItem('token', token)
                    const result = await this.$store.dispatch("auth/initialize", token);
                    if (result) {
                        const currentUser = store.state.auth.user;
                        if (currentUser.plan_id || !env.checkout) {
                            await this.$router.push('/home');
                        } else {
                            const token = localStorage.getItem('token')
                            await this.$router.push('/checkout?token=' + token);
                        }
                    }
                } catch (e) {
                    console.error('Mentors Login By Google: ', e);
                    this.$notifyError('Erro ao logar com o Google!');
                    await this.$router.push('/login');
                }

            } else {
                // Redireciona para o login se não tiver token
                await this.$router.push('/login');
            }
        }
    },

    created() {
        this.verifyCredentials();
    }
};
</script>
